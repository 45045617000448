<template>
  <div class="flex" :class="cardClass">
    <va-card
      :title="title"
      :style="computedStyle"
      :class="computedClass"
    >
      <template slot="actions">
        <!-- <va-icon :name="'at-icon card_hamburger'"></va-icon> -->
      </template>

      <!-- card-expand arrows -->
      <button class="nav__left" v-show="(currentLevel > 1 && currentLevel <= numOfLevels) && isExpandable" v-on:click="collapse">
        <va-icon name="at-icon l_arrow1-left"/>
      </button>
      <button class="nav__right" v-show="(currentLevel > 0 && currentLevel < numOfLevels) && isExpandable" v-on:click="expand">
        <va-icon name="at-icon l_arrow1-right"/>
      </button>
      <!-- card-expand arrows -->

      <div class="row">
        <div class="flex" :class="content1and2Class">
          <div class="row">
            <div class="flex" :class="contentClass">
              <slot name="content1"></slot>
            </div>
            <div class="flex" :class="[contentClass, {'mt-5': currentLevel === 3} ]" v-show="currentLevel > 1">
              <slot name="content2"></slot>
            </div>
          </div>
        </div>
        <div class="flex content-3" :class="content3Class" v-show="currentLevel > 2">
          <slot name="content3"></slot>
        </div>
      </div>
    </va-card>
  </div>
</template>

<script>
export default {
  name: 'AtExpandableCard',
  props: {
    minWidth: {
      type: Number,
      default: 4,
    },
    maxWidth: {
      type: Number,
      default: 12,
    },
    title: {
      type: String,
      default: 'Default Title',
    },
    cardStatus: {
      type: Number,
      default: 0,
    },
    isExpandable: {
      type: Boolean,
      default: true,
    },
  },
  data () {
    return {
      currentLevel: 1,
      numOfLevels: 3,
      cardMinWidth: this.minWidth,
      cardMaxWidth: this.maxWidth,
      cardClass: 'xs' + this.minWidth,
      content1and2Class: 'xs12',
      contentClass: 'xs12',
      content3Class: 'xs' + this.maxWidth,
    }
  },
  mounted () {
    // get number of levels based on used slots
    this.numOfLevels = Object.keys(this.$slots).length ?? Object.keys(this.$slots).length
  },
  methods: {
    setCardWidth () {
      let cardWidth = 0

      switch (this.currentLevel) {
        case 1:
          cardWidth = this.cardMinWidth
          this.contentClass = 'xs12'
          this.content1and2Class = 'xs12'
          break

        case 2:
          cardWidth = this.cardMinWidth <= 6 ? (this.cardMinWidth * 2) : 12 - this.cardMinWidth
          this.contentClass = 'xs6'
          this.content1and2Class = 'xs12'
          break

        case 3:
          cardWidth = this.cardMaxWidth
          this.contentClass = 'mt-2 mb-2 xs12'
          this.content1and2Class = 'xs' + this.cardMinWidth
          this.content3Class = 'xs' + (12 - this.cardMinWidth)
          break
      }

      this.cardClass = 'xs' + cardWidth
    },
    expand () {
      if (this.currentLevel > 0 && this.currentLevel < this.numOfLevels) {
        this.currentLevel++
      }

      this.setCardWidth()
    },
    collapse () {
      if (this.currentLevel > 1 && this.currentLevel <= this.numOfLevels) {
        this.currentLevel--
      }

      this.setCardWidth()
    },
  },
  computed: {
    computedClass () {
      if (this.cardStatus === 2 || this.cardStatus === 3) {
        return 'va-card--danger'
      } else if (this.cardStatus === 4) {
        return 'va-card--disabled'
      }

      return ''
    },
    computedStyle () {
      const style = {
        /* Update styles via JS Helpers (currently they are being changed in a global watcher) */
        // '--primary-color-hue': hex2hsl(this.$themes.primary).h,
        // '--primary-color': this.$themes.primary,

        /* Alternative: Update styles via JS Helpers */
        // background: cardGradient(this.$themes.primary, this.$status.value, this.$mode.value),
        // boxShadow: genShadow(this.$themes.primary, this.$status.value, this.$mode.value),
        // borderRadius: genRadius({ topLeft: '24px', bottomRight: '24px' }),
      }
      return style
    },
  },
}
</script>

<style lang="scss">
</style>
