<template>
  <at-expandable-card
    :title="$t(`data.${dataName}`)"
    :card-status="getCardLevel()"
    :minWidth=4
    :maxWidth=12
    :is-expandable="isExpandable"
  >
    <!-- Now -->
    <template v-slot:content1>
      <div class="content">
        <div class="content__main">
          <div class="content__main__top">
            <va-icon :name="dataIcon"></va-icon>
            <span v-html="unitFormat(dataNowVal, null, dataNowUnits)"></span>
          </div>
          <div :style="{visibility: dataName !== 'ppfd' ? 'visible' : 'hidden'}" class="content__main__bottom" :class="'value__status'">
            {{ dataNowLevelText }}
          </div>
        </div>
        <div class="content__bottom">
          <div class="content__bottom--center" :style="{visibility: dataNowRef ? 'visible' : 'hidden'}">
            <va-icon :name="'at-icon l_target'"></va-icon>
            <span v-html="unitFormat(dataNowRef, null, dataNowUnits)"></span>
          </div>
        </div>
      </div>
    </template>
    <!-- Last Hour -->
    <template v-slot:content2 v-if="isExpandable">
      <div class="content">
        <div class="content__main">
          <div class="content__main__top">
            <va-icon :name="computedTrendIcon"></va-icon>
            <span v-html="unitFormat(dataLastHourAvg, dataLastHourVar, dataNowUnits)"></span>
          </div>
          <div class="content__main__bottom">
            {{ $t(`timeData.lastHour`) }}
          </div>
        </div>
        <div class="content__bottom">
          <div class="content__bottom--space-between">
            <div>
              <va-icon :name="'at-icon l_arrow3-down'"></va-icon>
              <span v-html="unitFormat(dataLastHourMin, null, dataNowUnits)"></span>
            </div>
            <div>
              <va-icon :name="'at-icon l_arrow3-up'"></va-icon>
              <span v-html="unitFormat(dataLastHourMax, null, dataNowUnits)"></span>
            </div>
          </div>
        </div>
      </div>
    </template>
    <!-- History -->
    <template v-slot:content3 v-if="isExpandable">
      <h6>{{ chartTitle }}</h6>
      <va-chart class="chart" ref="dataChart" :data="chartData" :options="chartOptions" :type="chartType"/>
    </template>
  </at-expandable-card>
</template>

<script>
import Vue from 'vue'
import moment from 'moment-timezone'
import AtExpandableCard from '../AtExpandableCard.vue'
import { styleHelpers } from '@/services/atmosphere-ui'

export default {
  name: 'data-card',
  data () {
    return {
    }
  },
  props: {
    id: {
      type: Number,
    },
    dataName: {
      type: String,
      default: '',
    },
    dataIcon: {
      type: String,
    },
    dataNowLevel: {
      type: [Number, String],
    },
    dataNowVal: {
      type: Number,
    },
    dataNowUnits: {
      type: String,
    },
    dataNowRef: {
      type: Number,
    },
    dataLastHourAvg: {
      type: Number,
    },
    dataLastHourVar: {
      type: Number,
    },
    dataLastHourMin: {
      type: Number,
    },
    dataLastHourMax: {
      type: Number,
    },
    dataLastHourTrend: {
      type: Number,
    },
    chartTitle: {
      type: String,
      default: 'Variation',
    },
    chartType: {
      type: String,
    },
    chartData: {
      type: Object,
      default: () => {
        return {
          labels: [],
          datasets: [],
        }
      },
    },
    chartOptions: {
      type: Object,
      default: () => {
        return {}
      },
    },
    isExpandable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    dataNowLevelText () {
      if (this.dataNowLevel === 'info' || this.dataNowLevel === 0) {
        return this.$t('timeData.now')
      } else if (this.dataNowLevel === 'warning' || this.dataNowLevel === 1) {
        return this.$t('timeData.now')
      } else if (this.dataNowLevel === 'error' || this.dataNowLevel === 2) {
        return this.$t('timeData.now')
      } else if (this.dataNowLevel === 'old' || this.dataNowLevel === 3) {
        return this.$t('timeData.old')
      } else if (this.dataNowLevel === 'not_available' || this.dataNowLevel === 4) {
        return this.$t('timeData.not_available')
      }
      return this.dataNowLevel
    },
    computedTrendIcon () {
      let icon = ''
      if (this.dataLastHourTrend === 0) { // down
        icon = 'at-icon l_arrow3-left'
      } else if (this.dataLastHourTrend === 1) {
        icon = 'at-icon l_arrow_right' // equal
      } else {
        icon = 'at-icon l_arrow3-right' // up
      }
      return icon
    },
    language () {
      return Vue.i18n.locale()
    },
  },
  methods: {
    getCardLevel () {
      if (this.dataNowLevel === 'info') {
        return 0
      } else if (this.dataNowLevel === 'warning') {
        return 1
      } else if (this.dataNowLevel === 'error') {
        return 2
      } else if (this.dataNowLevel === 'old') {
        return 3
      }
      return this.dataNowLevel
    },
    unitFormat (value, valueVar, units) {
      if (valueVar) {
        units = '±' + styleHelpers.unitFormat(Math.round(Math.sqrt(valueVar)), units)
      }
      return styleHelpers.unitFormat(value, units)
    },
  },
  watch: {
    'language' () {
      if (this.isExpandable) {
        const timezone = moment().tz(this.$farmTimezone).zoneAbbr()
        this.chartOptions.scales.yAxes[0].scaleLabel.labelString = this.$t(`data.${this.dataName}`)
        this.chartOptions.scales.xAxes[0].scaleLabel.labelString = this.$t('timeData.time') + ' (' + timezone + ')'
        this.$refs.dataChart.$refs.chart.chartOptions = this.chartOptions
        this.$refs.dataChart.$refs.chart.refresh()
      }
    },
  },
  components: {
    AtExpandableCard,
  },
}
</script>

<style lang="scss">
.cards-container {
  .va-card {
    margin: 0;
  }
}

.value__status {
  color: hsl(var(--primary-color-hue), 40%, 30%) !important;
}
</style>
